import request from '@/utils/request'
import { BASE_API, ANCARE_API } from '@/utils/consts'

// 查询任务列表
export function queryTaskList(data) {
  return request(`${BASE_API}/task/list`, {
    data,
    method: 'post'
  })
}

// 查询任务历史记录
export function queryTaskHistoryList(data) {
  return request(`${BASE_API}/task/list_history`, {
    data,
    method: 'post'
  })
}

// 更新任务
export function updateTask(data) {
  return request(`${BASE_API}/task/update`, {
    data,
    method: 'put'
  })
}

// 完成任务
export function finishTask(reservationItemId) {
  return request(`${BASE_API}/task/finished/${reservationItemId}`, {
    method: 'put'
  })
}

// 查询任务详情
export function queryTaskDetail(taskId) {
  return request(`${BASE_API}/task/show/${taskId}`)
}

// 创建BPS首诊记录
export function createBPSVisit({ data }) {
  return request(`${BASE_API}/bps/create`, {
    method: 'POST',
    data
  })
}

// 获取需求评估
export function queryRequirementAnalysis(data) {
  return request(`${BASE_API}/requirement-analysis/show/${data.taskId}`)
}

// 编辑表头问题类型
export function fetchEditQesTwo(data) {
  return request(`${BASE_API}/customer/update_question_type_two`, {
    method: 'PUT',
    data
  })
}

// 添加需求评估
export function createRequirementAnalysis(data) {
  return request(`${BASE_API}/requirement-analysis/save`, {
    method: 'POST',
    data
  })
}

// 更新BPS首诊记录
export function updateBPSVisit({ data }) {
  return request(`${BASE_API}/bps/update`, {
    method: 'PUT',
    data
  })
}

// 查询任务详情
export function queryBPSDetail({ customer_id }) {
  return request(`${BASE_API}/bps/show_latest/${customer_id}`)
}

// export function queryBPSDetailBy({taskId}) {
//   return request(`${BASE_API}/bps/show/${taskId}`)
// }

// 查询任务详情
export function queryBPSHostoryDetail({ id }) {
  return request(`${BASE_API}/bps/show/${id}`)
}

// 查询BPS评估报告
export function queryEvaluation(data) {
  return request(`${BASE_API}/bps/report/show/${data.taskId}`)
}
export function queryNewEvaluation(data) {
  return request(`${BASE_API}/new_bps_report/show/${data.taskId}`)
}
//查询BPS评估报告历史记录
export function fetchEvaluationHistoryRecords({ customerId, pageNo = 1, pageSize = 10 }) {
  return request(`${BASE_API}/bps/report/list_history/${customerId}`, {
    data: {
      pageNo,
      pageSize
    }
  })
}

// 添加BPS评估报告
export function createEvaluation(data) {
  return request(`${BASE_API}/bps/report/create`, {
    method: 'POST',
    data
  })
}
export function createNewEvaluation(data) {
  return request(`${BASE_API}/new_bps_report/create`, {
    method: 'POST',
    data
  })
}
// 编辑BPS评估报告
export function updateEvaluation(data) {
  return request(`${BASE_API}/bps/report/update`, {
    method: 'POST',
    data
  })
}
export function updateNewEvaluation(data) {
  return request(`${BASE_API}/new_bps_report/update`, {
    method: 'POST',
    data
  })
}

// 查询脑健康检测历史记录
export function fetchDetectionHistoryRecords({ customerId, pageNo = 1, pageSize = 10 }) {
  return request(`${BASE_API}/detection/list_history/${customerId}`, {
    data: {
      pageNo,
      pageSize
    }
  })
}

//查询需求分析历史记录
export function fetchDemandHistoryRecords({ customerId, pageNo = 1, pageSize = 10 }) {
  return request(`${BASE_API}/requirement-analysis/list_history/${customerId}`, {
    data: {
      pageNo,
      pageSize
    }
  })
}

//查询通用历史记录
export function fetchGeneralHistory({ customerId, pageNo = 1, pageSize = 10 }) {
  return request(`${BASE_API}/general_record/list_history/${customerId}`, {
    data: {
      pageNo,
      pageSize
    }
  })
}

// 查询BPS历史记录
export function fetchBPSHistoryRecords({ customerId, pageNo = 1, pageSize = 10 }) {
  return request(`${BASE_API}/bps/list_history/${customerId}`, {
    data: {
      pageNo,
      pageSize
    }
  })
}

// 查询量表历史记录
export function fetchScaleHistoryRecords({ customerId, pageNo = 1, pageSize = 10 }) {
  return request(`${BASE_API}/scale_log/getScaleLogByCustomer/${customerId}`, {
    data: {
      pageNo,
      pageSize
    }
  })
}

// 创建沙盘影射分析记录表/sandtable/create
export function createSandTable(data) {
  return request(`${BASE_API}/sandtable/save`, {
    method: 'POST',
    data
  })
}

// 查询沙盘影射分析记录表
export function querySand(data) {
  return request(`${BASE_API}/sandtable/show/${data.taskId}`)
}

// 创建绘画影射分析记录表/sandtable/create
export function createDrawing(data) {
  return request(`${BASE_API}/drawing/save`, {
    method: 'POST',
    data
  })
}

// 查询绘画影射分析记录表
export function queryDrawing(data) {
  return request(`${BASE_API}/drawing/show/${data.taskId}`)
}

//创建心理服务完整表
export function createSubsequent(data) {
  return request(`${BASE_API}/subsequent_visit/save`, {
    method: 'POST',
    data
  })
}

//保存通用记录表
export function saveGeneral(data) {
  return request(`${BASE_API}/general_record/save`, {
    method: 'POST',
    data
  })
}


//查询通用记录表
export function queryGeneral(data) {
  return request(`${BASE_API}/general_record/show/${data.taskId}`)
}

// 查询心理服务完整表
export function querySubsequent(data) {
  return request(`${BASE_API}/subsequent_visit/show/${data.taskId}`)
}

// 查询绘画历史记录
export function fetchPaintingHistoryRecords({ customerId, pageNo = 1, pageSize = 10 }) {
  return request(`${BASE_API}/drawing/list_history/${customerId}`, {
    data: {
      pageNo,
      pageSize
    }
  })
}

// 查询fetchSandHistoryRecords
export function fetchSandHistoryRecords({ customerId, pageNo = 1, pageSize = 10 }) {
  return request(`${BASE_API}/sandtable/list_history/${customerId}`, {
    data: {
      pageNo,
      pageSize
    }
  })
}

// 查询负责历史记录fetchSubsequentHistoryRecords
export function fetchSubsequentHistoryRecords({ customerId, pageNo = 1, pageSize = 10 }) {
  return request(`${BASE_API}/subsequent_visit/list_history/${customerId}`, {
    data: {
      pageNo,
      pageSize
    }
  })
}

// 查看DPP循程治疗方案
export function queryDpp(data) {
  return request(`${BASE_API}/dpp-cycle-therapy/show/${data.id}`)
}

// 添加DPP循程治疗方案
export function createDpp(data) {
  return request(`${BASE_API}/dpp-cycle-therapy/save`, {
    method: 'POST',
    data
  })
}

// 查询历史治疗方案
export function queryDppHistory(data) {
  return request(`${BASE_API}/dpp-cycle-therapy/list_history`, {
    method: 'POST',
    data
  })
}

// 保存艺术疗愈记录表
export function createHealing(data) {
  return request(`${BASE_API}/art_healing/save`, {
    method: 'POST',
    data
  })
}

// 查询艺术疗愈记录表
export function queryHealing(data) {
  return request(`${BASE_API}/art_healing/show/${data.taskId}`)
}

// 查询艺术疗愈记录表历史记录fetchSubsequentHistoryRecords
export function fetchHealingHistoryRecords({ customerId, pageNo = 1, pageSize = 10 }) {
  return request(`${BASE_API}/art_healing/list_history/${customerId}`, {
    data: {
      pageNo,
      pageSize
    }
  })
}

// 查询居家心理训练记录表
export function queryMentalTraining(data) {
  return request(`${BASE_API}/mental_training_record/show/${data.taskId}`)
}

// 保存居家心理训练记录表
export function createMentalTraining(data) {
  return request(`${BASE_API}/mental_training_record/save`, {
    method: 'POST',
    data
  })
}

// 居家心理训练记录表历史记录
export function fetchHomePsychologyHistoryRecords({ customerId, pageNo = 1, pageSize = 10 }) {
  return request(`${BASE_API}/mental_training_record/list_history/${customerId}`, {
    data: {
      pageNo,
      pageSize
    }
  })
}

//查看DPP循程治疗方案
export function getDppByCustomerId({ customerId }) {
  return request(`${BASE_API}/dpp-cycle-therapy/show_by/${customerId}`)
}

// 获取模版
export function fetchTempList() {
  return request(`${BASE_API}/custom_serve_report/module/list`)
}

// 新增自定义表单-阶段反馈报告-服务记录
export function fetchAddExcel(data) {
  if (data.type == 'period_evaluate_report') {
    return request(`${BASE_API}/task/pre_period_evaluate_report`, {
      method: 'POST',
      data: {
        reservationItemId: data.reservationItemId,
        customerId: data.customerId,
        reportName: data.reportName,
        docName: data.docName
      }
    })
  } else {
    return request(`${BASE_API}/task/pre_common_serve_record`, {
      method: 'POST',
      data: {
        reservationItemId: data.reservationItemId,
        customerId: data.customerId,
        reportName: data.reportName,
        docName: data.docName,
        columnType: data.columnType
      }
    })
  }
}

// 聚合成一个了
export function fetchOneStepSaveExcel(data) {
  return request(`${BASE_API}/task/save_custom_task_and_doc`, {
    method: 'POST',
    data
  })
}
// 首次创建先要保存任务+保存表单
// 保存自定义任务
export function fetchSaveIdExcel(data) {
  return request(`${BASE_API}/task/save_custom_task`, {
    method: 'POST',
    data
  })
}
// 保存自定义表单
export function fetchSaveExcel(data) {
  switch (data.type) {
    case 'period_evaluate_report':
      delete data.reportName
      delete data.type
      return request(`${BASE_API}/custom_serve_report/save/period_evaluate_report`, {
        method: 'POST',
        data
      })
    case 'serve_record_module_one':
      delete data.type
      return request(`${BASE_API}/custom_serve_report/save/serve_record_module_one`, {
        method: 'POST',
        data
      })
    case 'serve_record_module_two':
      delete data.type
      return request(`${BASE_API}/custom_serve_report/save/serve_record_module_two`, {
        method: 'POST',
        data
      })
    case 'serve_record_module_three':
      delete data.type
      return request(`${BASE_API}/custom_serve_report/save/serve_record_module_three`, {
        method: 'POST',
        data
      })
  }

}

// 查看阶段反馈报告
export function fetchStageExcel(data) {
  return request(`${BASE_API}/custom_serve_report/period_evaluate_report/detail/${data.id}`, {
    method: 'get'
  })
}
export function fetchStageEndExcel(data) {
  return request(`${BASE_API}/conclude_report/detail/${data.id}`, {
    method: 'get'
  })
}
// 编辑阶段性评估表单
export function editStageExcel(data) {
  return request(`${BASE_API}/custom_serve_report/update/period_evaluate_report`, {
    method: 'POST',
    data
  })
}
export function editStageEndExcel(data) {
  return request(`${BASE_API}/conclude_report/update`, {
    method: 'POST',
    data
  })
}

// 查看心理服务完整表 报告
export function fetchServiceComolexExcel(data) {
  return request(`${BASE_API}/custom_serve_report/subsequent_visit_record_custom/detail/${data.id}`, {
    method: 'get'
  })
}
// 编辑心理服务完整表 表单
export function editServiceComolexExcel(data) {
  return request(`${BASE_API}/custom_serve_report/update/subsequent_visit_record_custom`, {
    method: 'POST',
    data
  })
}

// 创建结案报告 "conclude_report/create_new/{reservationItemId}",
export function fetchCreateEndReport(data) {
  return request(`${BASE_API}/conclude_report/create_new/${data.id}`, {
    method: 'get'
  })
}
// 查看模版1报告
export function fetchExcel1(data) {
  return request(`${BASE_API}/custom_serve_report/serve_record_module_one/detail/${data.id}`, {
    method: 'get'
  })
}
// 编辑模版1表单
export function editExcel1(data) {
  return request(`${BASE_API}/custom_serve_report/update/serve_record_module_one`, {
    method: 'POST',
    data
  })
}

// 查看模版2报告
export function fetchExcel2(data) {
  return request(`${BASE_API}/custom_serve_report/serve_record_module_two/detail/${data.id}`, {
    method: 'get'
  })
}
// 编辑模版2表单
export function editExcel2(data) {
  return request(`${BASE_API}/custom_serve_report/update/serve_record_module_two`, {
    method: 'POST',
    data
  })
}

// 查看模版3报告
export function fetchExcel3(data) {
  return request(`${BASE_API}/custom_serve_report/serve_record_module_three/detail/${data.id}`, {
    method: 'get'
  })
}
// 编辑模版3表单
export function editExcel3(data) {
  return request(`${BASE_API}/custom_serve_report/update/serve_record_module_three`, {
    method: 'POST',
    data
  })
}

// 评估报告
export function fetchBpsEvaluation(data) {
  return request(`${BASE_API}/task/evaluation_report/list/${data.id}`, {
    method: 'GET'
  })
}

// 测评报告
export function fetchReportEvaluation(data) {
  // `${BASE_API}/pc/customer/reports/listAk`
  return request(`${BASE_API}/mini/customer/exam/list`, {
    method: 'POST',
    data
  })
}


export function fetchHomeSubsequent(data) {
  return request(`${BASE_API}/task/psychological_service/list/${data.id}`, {
    method: 'GET'
  })
}
export function fetchHomeDoctor(data) {
  return request(`${BASE_API}/task/family_service/list/${data.id}`, {
    method: 'GET'
  })
}
export function fetchScaleDetection(data) {
  return request(`${BASE_API}/task/special_examining/list/${data.id}`, {
    method: 'GET'
  })
}
export function fetchHealingSandPainting(data) {
  return request(`${BASE_API}/task/other_service/list/${data.id}`, {
    method: 'GET'
  })
}
export function fetchChangeTitle(data) {
  return request(`${BASE_API}/task/change_report_name`, {
    method: 'POST',
    data
  })
}

// 心理服务记录 详情
export function fetchSubsquentBrief(data) {
  return request(`${BASE_API}/subsequent_brief_visit/show/${data.id}`, {
    method: 'GET'
  })
}
// 心理服务记录 保存/编辑
export function fetchSaveEditSubsquentBrief(data) {
  return request(`${BASE_API}/subsequent_brief_visit/save`, {
    method: 'POST',
    data
  })
}
// 心理服务记录 编辑
export function fetchEditSubsquentBrief(data) {
  return request(`${BASE_API}/subsequent_brief_visit/show/${data.id}`, {
    method: 'POST',
    data
  })
}
// 获取服务小组信息
export function fetchServiceTeam(data) {
  return request(`${BASE_API}/customer/service_team/${data.customerId}`, {
    method: 'GET'
  })
}

// 查询客户最新复诊记录简表
export function fetchLastTargetSubsquentBrief(data) {
  return request(`${BASE_API}/subsequent_brief_visit/show/customer_latest`, {
    method: 'POST',
    data
  })
}

// 查询是否有需要同步的列表[心理服务记录,]
export function fetchNeedCopyList(id) {
  return request(`${BASE_API}/task/exist_copy_item/${id}`, {
    method: 'GET'
  })
}
// 同步列表
export function fetchCopyToList(data) {
  return request(`${BASE_API}/task/copy_to_item`, {
    method: 'POST',
    data
  })
}

export function fetchTriggerShared1(data) {
  return request(`${BASE_API}/custom_serve_report/period_evaluate_report/share/${data.taskableType}/${data.docId}/${data.isShared}`, { method: 'GET' })
}
export function fetchTriggerShared2(data) {
  return request(`${BASE_API}/custom_serve_report/psychology_scale/share/${data.docId}/${data.isShared}`, { method: 'GET' })
}